import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "./store";
import { ModalPlugin } from "bootstrap-vue";
import JsonCSV from "vue-json-csv";
import VueCryptojs from "vue-cryptojs";
import { jwtDecode } from "jwt-decode";
import router from "./router";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require("vue-moment"));
Vue.use(VueRouter);
Vue.use(store);
Vue.use(ModalPlugin);
Vue.use(require("vue-moment"));
Vue.use(VueCryptojs);
Vue.component("downloadCsv", JsonCSV);

import "./assets/custom.scss";

Vue.config.productionTip = false;

axios.defaults.baseURL = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://be.adfluential.com";
axios.defaults.headers.common["Authorization"] = localStorage.getItem('accessToken');
axios.defaults.headers.common["Content-Type"] = "application/json";

// Add these variables at the top of the file, before the interceptors
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

function isValidJWT(token) {
  try {
    jwtDecode(token);
    return true;
  } catch {
    return false;
  }
}

function isPublicRoute(url) {
  const publicRoutes = [
    '/user/login',
    '/user/refresh',
    '/user/twofactor',
  ];
  return publicRoutes.some(route => url.includes(route));
}

axios.interceptors.request.use(async (request) => {
  // Skip token handling for refresh token requests
  if (request.url.includes('/user/refresh') && request.headers.Authorization) {
    // Keep the existing Authorization header as is
    return request;
  }
  
  // Skip token check for public routes
  if (isPublicRoute(request.url)) {
    delete request.headers.Authorization;
    return request;
  }

  const token = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  // If we have an explicit Authorization header in the request, use it
  if (request.headers.Authorization) {
    return request;
  }

  // If no tokens exist at all, redirect to login
  if (!token && !refreshToken) {
    console.error("No tokens found in store");
    if (router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'TwoFactor') {
      router.push({ name: "Login" });
    }
    return Promise.reject(new Error('No tokens available'));
  }

  // Check if token is expired or invalid
  if (!isValidJWT(token)) {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const response = await axios.post("/user/refresh", {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('refreshToken')}` }
        });
        
        store.commit("saveAccessToken", response.data.accessToken);
        store.commit("saveRefreshToken", response.data.refreshToken);
        
        request.headers.Authorization = response.data.accessToken;
        axios.defaults.headers.common["Authorization"] = response.data.accessToken;
        
        processQueue(null, response.data.accessToken);
        return request;
      } catch (error) {
        processQueue(error, null);
        store.commit("clearTokens");
        if (router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'TwoFactor') {
          router.push({ name: "Login" });
        }
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    } else {
      // Wait for the refresh to complete
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(token => {
        request.headers.Authorization = token;
        return request;
      }).catch(err => {
        return Promise.reject(err);
      });
    }
  }

  // Valid token exists, use it
  request.headers.Authorization = token;
  axios.defaults.headers.common["Authorization"] = token;

  if (window.location.hostname === "localhost") {
    console.log("Starting Request", JSON.stringify(request, null, 2));
  }
  return request;
});

// Helper function to load environment data
// async function loadEnvironmentData() {
//   try {
//     const response = await axios.get("/environment");
//     store.commit("savePlatforms", response.data.platforms);
//     store.commit("savePlatformAccountTimezones", response.data.timezones);
//     store.commit("savePlatformAccounts", response.data.accounts);
//     store.commit("saveMediaBuyers", response.data.buyers);
//     store.commit("saveLanguages", response.data.languages);
//     store.commit("saveiabCategories", response.data.iabCategories);
//     store.commit("saveBrands", response.data.brands);
//     store.commit("saveCountries", response.data.countries);
//     store.commit("saveVerticals", response.data.verticals);
//     store.commit("savePartners", response.data.partners);
//     store.commit("saveTags", response.data.tags);
//     store.commit("saveLeadTypes", response.data.leadTypes);
//     store.commit("saveActors", response.data.actors);
//     store.commit("saveEditors", response.data.editors);
//     store.commit("saveScripts", response.data.scripts);
//     store.commit("saveTexts", response.data.texts);
//     store.commit("saveImages", response.data.images);
//     store.commit("saveCreativeAssets", response.data.creativeAssets);
//     store.commit("saveTakes", response.data.takes);
//     store.commit("saveHooks", response.data.hooks);
//     store.commit("saveLocations", response.data.locations);
//     store.commit("saveTitles", response.data.titles);
//     store.commit("saveDescriptions", response.data.descriptions);
//     store.commit("saveCaptions", response.data.captions);
//     store.commit("saveMusic", response.data.music);
//     store.commit("saveSfx", response.data.sfx);
//     store.commit("saveSpecial", response.data.special);
//     store.commit("saveVisuals", response.data.visuals);
//     store.commit("saveBroll", response.data.broll);
//     return true;
//   } catch (error) {
//     console.error("Failed to load environment data:", error);
//     return false;
//   }
// }

axios.interceptors.response.use(
  (response) => {
    if (window.location.hostname === "localhost") {
      console.log("Response", JSON.stringify(response, null, 2));
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    // If error is 401 and we haven't tried to refresh the token yet
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      console.log('Received 401, attempting token refresh');
      originalRequest._retry = true;
      
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        console.log('No refresh token available, redirecting to login');
        store.commit('clearTokens');
        router.push({ name: 'Login' });
        return Promise.reject(error);
      }
      
      try {
        console.log('Calling refresh endpoint');
        const response = await axios.post('/user/refresh', {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('refreshToken')}` }
        });
        
        console.log('Token refresh successful');
        store.commit('saveAccessToken', response.data.accessToken);
        store.commit('saveRefreshToken', response.data.refreshToken);
        
        // Update the failed request with the new token
        originalRequest.headers.Authorization = response.data.accessToken;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        store.commit('clearTokens');
        router.push({ name: 'Login' });
        return Promise.reject(refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);

store.commit("saveStartDate", null);
store.commit("saveEndDate", null);
store.commit("saveStartDate2", null);
store.commit("saveEndDate2", null);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
