import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Platforms",
    component: () => import("../views/Platforms.vue"),
    props: true,
  },
  {
    path: "/mediabuyer",
    name: "MediaBuyer",
    component: () => import("../views/MediaBuyer.vue"),
    props: true,
  },
  {
    path: "/accounts/:platformUuid?/:platformAccountTimezoneUuid?/:platformAccountUuid?",
    name: "Accounts",
    component: () => import("../views/Accounts.vue"),
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    props: true,
  },
  {
    path: "/twofactor",
    name: "TwoFactor",
    component: () => import("../views/TwoFactor.vue"),
    props: true,
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/Assets.vue"),
    props: true,
  },
  {
    path: "/campaigns/day/:platformUuid/:platformAccountTimezoneUuid?/:platformAccountUuid?/:platformCampaignUuid?",
    name: "CampaignDay",
    component: () => import("../views/CampaignDay.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/groups/:platformUuid",
    name: "CampaignGroups",
    component: () => import("../views/CampaignGroups.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/contents/:platformUuid",
    name: "CampaignContents",
    component: () => import("../views/CampaignContents.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/widgets/:platformUuid/:platformAccountTimezoneUuid?/:platformAccountUuid?/:platformCampaignUuid?",
    name: "CampaignWidgets",
    component: () => import("../views/CampaignWidgets.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/subwidgets/:platformUuid/:platformAccountTimezoneUuid?/:platformAccountUuid?/:platformCampaignUuid?",
    name: "CampaignSubWidgets",
    component: () => import("../views/CampaignSubWidgets.vue"),
    props: true,
    params: true,
  },
  {
    path: "/campaigns/:platformUuid?",
    name: "Campaigns",
    component: () => import("../views/Campaigns.vue"),
    props: true,
  },
  {
    path: "/widgets/:platformUuid?/:platformAccountTimezoneUuid?",
    name: "WidgetPerformance",
    component: () => import("../views/WidgetPerformance.vue"),
    props: true,
  },
  {
    path: "/subwidgets/:platformUuid?/:platformAccountTimezoneUuid?",
    name: "SubWidgetPerformance",
    component: () => import("../views/SubWidgetPerformance.vue"),
    props: true,
  },
  {
    path: "/contentperformance",
    name: "ContentPerformance",
    component: () => import("../views/ContentPerformance.vue"),
    props: true,
  },
  {
    path: "/tracking",
    name: "ClickChannel",
    component: () => import("../views/ClickChannel.vue"),
    props: true,
  },
  {
    path: "/tracking/performance",
    name: "ClickChannelPerformance",
    component: () => import("../views/ClickChannelPerformance.vue"),
    props: true,
  },
  {
    path: "/tracking/performance/breakdown/:clickChannelUuid",
    name: "ClickChannelPerformanceBreakdown",
    component: () => import("../views/ClickChannelPerformanceBreakdown.vue"),
    props: true,
  },
  {
    path: "/tracking/clickstream",
    name: "ClickStream",
    component: () => import("../views/ClickStream.vue"),
    props: true,
  },
  {
    path: "/tracking/postbackerrors",
    name: "PostbackErrors",
    component: () => import("../views/PostbackErrors.vue"),
    props: true,
  },
  {
    path: "/tracking/:clickChannelUuid?",
    name: "ClickChannelSingle",
    component: () => import("../views/ClickChannelSingle.vue"),
    props: true,
  },
  {
    path: "/creativeAssets",
    name: "creativeAssets",
    component: () => import("../views/CreativeAssets.vue"),
    props: true,
  },
  {
    path: "/creativeAssets/:creativeAssetUuid?",
    name: "creativeAssetSingle",
    component: () => import("../views/CreativeAssetSingle.vue"),
    props: true,
  },
  {
    path: "/campaignbuild",
    name: "CampaignBuild",
    component: () => import("../views/CampaignBuild.vue"),
    props: true,
  },
  {
    path: "/platforms",
    name: "Platform",
    component: () => import("../views/Platform.vue"),
    props: true,
  },
  {
    path: "/platforms/accounts",
    name: "PlatformAccounts",
    component: () => import("../views/PlatformAccounts.vue"),
    props: true,
  },
  {
    path: "/platforms/accounts/audiences",
    name: "PlatformAccountAudiences",
    component: () => import("../views/PlatformAccountAudiences.vue"),
    props: true,
  },
  {
    path: "/partner/campaigns",
    name: "PartnerCampaigns",
    component: () => import("../views/PartnerCampaign.vue"),
    props: true,
  },
  {
    path: "/partner/performance",
    name: "PartnerPerformance",
    component: () => import("../views/PartnerPerformance.vue"),
    props: true,
  },
  {
    path: "/partner/campaigns/performance",
    name: "PartnerCampaignPerformance",
    component: () => import("../views/PartnerCampaignPerformance.vue"),
    props: true,
  },
  {
    path: "/partner/delivery",
    name: "PartnerDelivery",
    component: () => import("../views/PartnerDelivery.vue"),
    props: true,
  },
  {
    path: "/partner/delivery/edit/:partnerDeliveryUuid?",
    name: "PartnerDeliverySingle",
    component: () => import("../views/PartnerDeliverySingle.vue"),
    props: true,
  },
  {
    path: "/interstitial",
    name: "Interstitial",
    component: () => import("../views/Interstitial.vue"),
    props: true,
  },
  {
    path: "/interstitial/group",
    name: "InterstitialGroup",
    component: () => import("../views/InterstitialGroup.vue"),
    props: true,
  },
  {
    path: "/interstitial/group/:interstitialGroupUuid",
    name: "InterstitialGroupSingle",
    component: () => import("../views/InterstitialGroupSingle.vue"),
    props: true,
  },
  {
    path: "/partner/campaign/groups",
    name: "PartnerCampaignGroups",
    component: () => import("../views/PartnerCampaignGroup.vue"),
    props: true,
  },
  {
    path: "/partner/campaign/groups/:partnerCampaignGroupUuid",
    name: "PartnerCampaignGroupSingle",
    component: () => import("../views/PartnerCampaignGroupSingle.vue"),
    props: true,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks.vue"),
    props: true,
  },
  {
    path: "/jobs/logs",
    name: "Logs",
    component: () => import("../views/Logs.vue"),
    props: true,
  },
  {
    path: "/leads/search",
    name: "ReceivedLeads",
    component: () => import("../views/ReceivedLeads.vue"),
    props: true,
  },
  {
    path: "/leads/sold",
    name: "SoldLeads",
    component: () => import("../views/SoldLeads.vue"),
    props: true,
  },
  {
    path: "/leads/withdrawn",
    name: "WithdrawnLeads",
    component: () => import("../views/WithdrawnLeads.vue"),
    props: true,
  },
  {
    path: "/leads/unsold",
    name: "UnsoldLeads",
    component: () => import("../views/UnsoldLeads.vue"),
    props: true,
  },
  {
    path: "/leads/returned",
    name: "ReturnedLeads",
    component: () => import("../views/ReturnedLeads.vue"),
    props: true,
  },
  {
    path: "/leads/rejected",
    name: "RejectedLeads",
    component: () => import("../views/RejectedLeads.vue"),
    props: true,
  },
  {
    path: "/partner/order",
    name: "PartnerOrder",
    component: () => import("../views/PartnerOrder.vue"),
    props: true,
  },
  {
    path: "/partner/data",
    name: "PartnerDataOrder",
    component: () => import("../views/PartnerDataOrder.vue"),
    props: true,
  },
  {
    path: "/partner/data/leads",
    name: "PartnerDataOrderLeads",
    component: () => import("../views/PartnerDataOrderLeads.vue"),
    props: true,
  },
  {
    path: "/partner",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
    props: true,
  },
  {
    path: "/brands",
    name: "Brands",
    component: () => import("../views/Brands.vue"),
    props: true,
  },
  {
    path: "/leads/type",
    name: "LeadType",
    component: () => import("../views/LeadType.vue"),
    props: true,
  },
  {
    path: "/leads/reports/sold",
    name: "LeadSoldReport",
    component: () => import("../views/LeadSoldReport.vue"),
    props: true,
  },
  {
    path: "/leads/reports/data",
    name: "DataSoldReport",
    component: () => import("../views/DataSoldReport.vue"),
    props: true,
  },
  {
    path: "/partner/reports/disposition",
    name: "PartnerFeedbackReport",
    component: () => import("../views/PartnerFeedbackReport.vue"),
    props: true,
  },
  {
    path: "/content",
    name: "Content",
    component: () => import("../views/Content.vue"),
    props: true,
  },
  {
    path: "/smsreplies",
    name: "SmsReplies",
    component: () => import("../views/SmsReplies.vue"),
    props: true,
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: () => import("../views/Reservations.vue"),
    props: true,
  },
  {
    path: "/pings",
    name: "Pings",
    component: () => import("../views/Pings.vue"),
    props: true,
  },
  {
    path: "/verticals",
    name: "Verticals",
    component: () => import("../views/Verticals.vue"),
  },
  {
    path: "/countries",
    name: "Countries",
    component: () => import("../views/Countries.vue"),
    props: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    props: true,
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    props: true,
  },
  {
    path: "/dnc/:dncUuid?",
    name: "Dnc",
    component: () => import("../views/Dnc.vue"),
    props: true,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("../views/Alert.vue"),
    props: true,
  },
  {
    path: "/webhooks",
    name: "Webhook",
    component: () => import("../views/Webhook.vue"),
    props: true,
  },
  {
    path: "/offerwalls",
    name: "OfferWalls",
    component: () => import("../views/OfferWalls.vue"),
    props: true,
  },
  {
    path: "/offerwalls/offers",
    name: "OfferWallOffers",
    component: () => import("../views/OfferWallOffers.vue"),
    props: true,
  },
  {
    path: "/offerwalls/reporting",
    name: "OfferWallReporting",
    component: () => import("../views/OfferWallReporting.vue"),
    props: true,
  },
  {
    path: "/offerwalls/stream/:streamUuid",
    name: "OfferWallStreamDetail",
    component: () => import("../views/OfferWallStreamDetail.vue"),
    props: true,
  },
  {
    path: '/offerwalloffers/new',
    name: 'NewOfferWallOffer',
    component: () => import("../views/OfferWallOfferDetail.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/offerwalloffers/:offerUuid/edit',
    name: 'EditOfferWallOffer',
    component: () => import("../views/OfferWallOfferDetail.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring',
    name: 'campaignMonitoring',
    component: () => import('@/views/CampaignMonitoring.vue'),
    meta: {
      requiresAuth: true,
      title: 'Campaign Monitoring'
    }
  },
  {
    path: '/monitoring/:id',
    name: 'campaignMonitorDetail',
    component: () => import('@/views/CampaignMonitorDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Monitor Details'
    }
  },
  {
    path: '/offerwall-requests',
    name: 'OfferWallRequests',
    component: () => import('@/views/OfferWallRequests.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/offerwalloffers/:offerUuid/reporting',
    name: 'OfferWallOfferReportingDetail',
    component: () => import('@/views/OfferWallOfferReportingDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offerwalls/reporting/questions',
    name: 'OfferWallQuestionStatistics',
    component: () => import('@/views/OfferWallQuestionStatistics.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offerwalls/reporting/brands',
    name: 'OfferWallBrandReporting',
    component: () => import('@/views/OfferWallBrandReporting.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offerwalls/reporting/offers',
    name: 'OfferWallOfferReporting',
    component: () => import('@/views/OfferWallOfferReporting.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offerwall/position-reporting',
    name: 'OfferWallPositionReporting',
    component: () => import('@/views/OfferWallPositionReporting.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/offerwall/hourly-statistics',
    name: 'OfferWallHourlyStatistics',
    component: () => import('@/views/OfferWallHourlyStatistics.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/offerwall/:offerWallUuid/distribution',
    name: 'OfferWallDistribution',
    component: () => import('@/views/OfferWallDistribution.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Allow access to public routes without authentication
  if (to.name === "Login" || to.name === "TwoFactor") {
    console.log(`Navigating to public route: ${to.name}`);
    next();
    return;
  }

  console.log(`Checking authentication for route: ${to.name}`);
  
  const token = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  
  console.log(`Token check: Access token ${token ? 'exists' : 'missing'}, Refresh token ${refreshToken ? 'exists' : 'missing'}`);
  
  // If no tokens exist at all, redirect to login
  if (!token && !refreshToken) {
    console.log("No tokens found, redirecting to login");
    next({ name: "Login" });
    return;
  }

  // Check token validity with improved function
  function isTokenValid(token) {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const isValid = decoded.exp > currentTime;
      console.log(`Token expiration check: Current time ${currentTime}, Token expires ${decoded.exp}, Valid: ${isValid}`);
      return isValid;
    } catch (error) {
      console.error("Token validation error:", error);
      return false;
    }
  }

  // If access token is valid, proceed
  if (isTokenValid(token)) {
    console.log("Access token is valid, proceeding to route");
    next();
    return;
  }

  console.log("Access token invalid, checking refresh token");
  
  // If access token is invalid but we have a refresh token, try to refresh
  if (refreshToken && isTokenValid(refreshToken)) {
    try {
      console.log("Attempting token refresh");
      const response = await axios.post("/user/refresh", {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('refreshToken')}` }
      });
      
      console.log("Token refresh successful");
      store.commit("saveAccessToken", response.data.accessToken);
      store.commit("saveRefreshToken", response.data.refreshToken);
      
      next();
    } catch (error) {
      console.error("Token refresh failed:", error);
      store.commit("clearTokens"); // Clear all tokens on refresh failure
      next({ name: "Login" });
    }
  } else {
    // If refresh token is also invalid, clear tokens and redirect to login
    console.log("No valid tokens available");
    store.commit("clearTokens");
    next({ name: "Login" });
  }
});

// function isValidJWT(token) {
//   if (!token) {
//     console.log("No token provided");
//     return false;
//   }
//   try {
//     const decoded = jwtDecode(token);
//     const currentTime = Math.floor(Date.now() / 1000);
//     if (decoded.exp && decoded.exp < currentTime) {
//       console.log("Token is expired");
//       return false;
//     }
//     return true;
//   } catch (error) {
//     console.error("JWT validation failed:", error.message);
//     return false;
//   }
// }

// async function loadEnvironmentData() {
//   try {
//     console.log("Loading environment data...");
//     const envResponse = await axios.get("/environment");
//     if (!envResponse.data) {
//       throw new Error("No environment data received");
//     }
    
//     // Store environment data
//     store.commit("savePlatforms", envResponse.data.platforms);
//     store.commit("savePlatformAccountTimezones", envResponse.data.timezones);
//     store.commit("savePlatformAccounts", envResponse.data.accounts);
//     store.commit("saveMediaBuyers", envResponse.data.buyers);
//     store.commit("saveLanguages", envResponse.data.languages);
//     store.commit("saveiabCategories", envResponse.data.iabCategories);
//     store.commit("saveBrands", envResponse.data.brands);
//     store.commit("saveCountries", envResponse.data.countries);
//     store.commit("saveVerticals", envResponse.data.verticals);
//     store.commit("savePartners", envResponse.data.partners);
//     store.commit("saveTags", envResponse.data.tags);
//     store.commit("saveLeadTypes", envResponse.data.leadTypes);
//     store.commit("saveActors", envResponse.data.actors);
//     store.commit("saveEditors", envResponse.data.editors);
//     store.commit("saveScripts", envResponse.data.scripts);
//     store.commit("saveTexts", envResponse.data.texts);
//     store.commit("saveImages", envResponse.data.images);
//     store.commit("saveCreativeAssets", envResponse.data.creativeAssets);
//     console.log("Environment data loaded successfully");
//     return true;
//   } catch (error) {
//     console.error("Failed to load environment:", error.message);
//     return false;
//   }
// }

export default router;
