import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { jwtDecode } from 'jwt-decode';

Vue.use(Vuex)

// First, let's create the helper function outside the store
function logTokenStatus() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');
  const refreshTokenExpiresAt = localStorage.getItem('refreshTokenExpiresAt');
  
  console.log('Token Status Check:');
  console.log(`Access Token: ${accessToken ? 'exists' : 'missing'}`);
  console.log(`Access Token Expires: ${accessTokenExpiresAt ? new Date(parseInt(accessTokenExpiresAt)).toISOString() : 'no expiry'}`);
  console.log(`Refresh Token: ${refreshToken ? 'exists' : 'missing'}`);
  console.log(`Refresh Token Expires: ${refreshTokenExpiresAt ? new Date(parseInt(refreshTokenExpiresAt)).toISOString() : 'no expiry'}`);
}

// Create store instance first
const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            key: 'adfluentialCache',
            paths: [
                'userEmail',
                'userUuid',
                'accessToken',
                'refreshToken',
                'platforms',
                'platformAccountTimezones',
                'platformAccounts',
                'mediaBuyers',
                'platformUuid',
                'platformAccountTimezoneUuid',
                'platformAccountUuid',
                'platformCampaignUuid',
                'dateRange',
                'dateRange2',
                'widgetFilter',
                'campaignFilter',
                'countries',
                'verticals',
                'languages',
                'iabCategories',
                'partners',
                'leadTypes',
                'users',
                'tags',
                'actors',
                'editors',
                'scripts',
                'texts',
                'images',
                'takes',
                'hooks',
                'locations',
                'titles',
                'descriptions',
                'captions',
                'music',
                'sfx',
                'special',
                'visuals',
                'broll',
                'creativeAssets',
                'brands',
                'offerWallOffers'
            ],
            storage: {
                getItem: key => {
                    const value = localStorage.getItem(key)
                    if (key === 'adfluentialCache' && value) {
                        const parsed = JSON.parse(value)
                        
                        // Check access token expiration
                        if (parsed.accessToken) {
                            const accessExpiry = localStorage.getItem('accessTokenExpiresAt')
                            if (accessExpiry && Date.now() > parseInt(accessExpiry)) {
                                parsed.accessToken = null
                            }
                        }
                        
                        // Check refresh token expiration
                        if (parsed.refreshToken) {
                            const refreshExpiry = localStorage.getItem('refreshTokenExpiresAt')
                            if (refreshExpiry && Date.now() > parseInt(refreshExpiry)) {
                                parsed.refreshToken = null
                            }
                        }
                        
                        return JSON.stringify(parsed)
                    }
                    return value
                },
                setItem: (key, value) => {
                    if (key === 'adfluentialCache') {
                        const parsed = JSON.parse(value)
                        
                        // Set access token expiration
                        if (parsed.accessToken) {
                            localStorage.setItem('accessTokenExpiresAt', (Date.now() + (600 * 1000)).toString())
                        }
                        
                        // Set refresh token expiration
                        if (parsed.refreshToken) {
                            localStorage.setItem('refreshTokenExpiresAt', (Date.now() + (604800 * 1000)).toString())
                        }
                    }
                    localStorage.setItem(key, value)
                },
                removeItem: key => localStorage.removeItem(key)
            }
        })
    ],
    state: {
        userEmail: "",
        userUuid: "",
        refreshToken: localStorage.getItem('refreshToken') || null,
        accessToken: localStorage.getItem('accessToken') || null,
        platforms: {},
        platformAccountTimezones: {},
        platformAccounts: {},
        mediaBuyers: {},
        platformUuid: "",
        platformAccountTimezoneUuid: "",
        platformAccountUuid: "",
        platformCampaignUuid: "",
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        dateRange2: {
            startDate: new Date(),
            endDate: new Date(),
        },            
        widgetFilter: "",
        campaignFilter: "",
        countries: [],
        verticals: [],
        languages: [],
        iabCategories: [],
        partners: [],
        leadTypes: [],
        users: [],
        tags: [],
        actors: [],
        editors: [],
        scripts: [],
        texts: [],
        images: [],
        takes: [],
        hooks: [],
        locations: [],
        titles: [],
        descriptions: [],
        captions: [],
        music: [],
        sfx: [],
        special: [],
        visuals: [],
        broll: [],
        brands: [],
        creativeAssets: [],
        offerWallOffers: [],
    },
    mutations: {
        logoutState (state, payload) {
            state = payload
        },                
        saveAccessToken (state, token) {
            state.accessToken = token;
            localStorage.setItem('accessToken', token);
            
            // Store expiration time
            try {
                const decoded = jwtDecode(token);
                if (decoded.exp) {
                    const expiresAt = decoded.exp * 1000; // Convert to milliseconds
                    localStorage.setItem('accessTokenExpiresAt', expiresAt.toString());
                    console.log(`Access token saved, expires: ${new Date(expiresAt).toISOString()}`);
                }
            } catch (error) {
                console.error('Error decoding access token:', error);
            }
            
            logTokenStatus();
        },        
        saveRefreshToken (state, token) {
            state.refreshToken = token;
            localStorage.setItem('refreshToken', token);
            
            // Store expiration time
            try {
                const decoded = jwtDecode(token);
                if (decoded.exp) {
                    const expiresAt = decoded.exp * 1000; // Convert to milliseconds
                    localStorage.setItem('refreshTokenExpiresAt', expiresAt.toString());
                    console.log(`Refresh token saved, expires: ${new Date(expiresAt).toISOString()}`);
                }
            } catch (error) {
                console.error('Error decoding refresh token:', error);
            }
            
            logTokenStatus();
        },
        saveUserEmail (state, payload) {
            state.userEmail = payload
        },
        saveUserUuid (state, payload) {
            state.userUuid = payload
        },        
        savePlatformUuid (state, payload) {
            state.platformUuid = payload
        },
        savePlatformAccountTimezoneUuid (state, payload) {
            state.platformAccountTimezoneUuid = payload
        },
        savePlatformAccountUuid (state, payload) {
            state.platformAccountUuid = payload
        },
        saveCampaignUuid (state, payload) {
            state.platformCampaignUuid = payload
        },        
        saveStartDate (state, payload) {
            state.dateRange.startDate = payload
        },
        saveEndDate (state, payload) {
            state.dateRange.endDate = payload
        },                                
        saveStartDate2 (state, payload) {
            state.dateRange2.startDate = payload
        },
        saveEndDate2 (state, payload) {
            state.dateRange2.endDate = payload
        },         
        saveWidgetFilter (state, payload) {
            state.widgetFilter = payload
        },         
        saveSubWidgetFilter (state, payload) {
            state.subWidgetFilter = payload
        },                 
        saveCampaignFilter (state, payload) {
            state.campaignFilter = payload
        },                 
        saveTagFilter (state, payload) {
            state.tagFilter = payload
        },             
        saveCampaignCategory (state, payload) {
            state.campaignCategory = payload
        },                        
        saveContentFilter (state, payload) {
            state.contentFilter = payload
        },         
        saveGroupFilter (state, payload) {
            state.groupFilter = payload
        },                 
        savePlatforms (state, payload) {
            state.platforms = payload
        },                         
        savePlatformAccountTimezones (state, payload) {
            state.platformAccountTimezones = payload
        },
        savePlatformAccounts (state, payload) {
            state.platformAccounts = payload
        },
        saveMediaBuyers (state, payload) {
            state.mediaBuyers = payload
        },     
        saveLanguages (state, payload) {
            state.languages = payload
        },           
        saveiabCategories (state, payload) {
            state.iabCategories = payload
        },                   
        saveBrands (state, payload) {
            state.brands = payload
        },                           
        saveCountries (state, payload) {
            state.countries = payload
        },             
        saveVerticals (state, payload) {
            state.verticals = payload
        },                           
        savePartners (state, payload) {
            state.partners = payload
        },                         
        saveLeadTypes (state, payload) {
            state.leadTypes = payload
        },                             
        saveTags (state, payload) {
            state.tags = payload
        },                                                                  
        saveUsers (state, payload) {
            state.users = payload
        },             
        saveActors (state, payload) {
            state.actors = payload
        },             
        saveEditors (state, payload) {
            state.editors = payload
        },                                                                          
        saveScripts (state, payload) {
            state.scripts = payload
        },                                                                          
        saveTexts (state, payload) {
            state.texts = payload
        },                                                                          
        saveImages (state, payload) {
            state.images = payload
        },                                                                                                  
        saveCreativeAssets (state, payload) {
            state.creativeAssets = payload
        },                                   
        saveTakes (state, payload) {
            state.takes = payload
        },
        saveHooks (state, payload) {
            state.hooks = payload
        },
        saveLocations (state, payload) {
            state.locations = payload
        },
        saveTitles (state, payload) {
            state.titles = payload
        },
        saveDescriptions (state, payload) {
            state.descriptions = payload
        },
        saveCaptions (state, payload) {
            state.captions = payload
        },
        saveMusic (state, payload) {
            state.music = payload
        },
        saveSfx (state, payload) {
            state.sfx = payload
        },
        saveSpecial (state, payload) {
            state.special = payload
        },
        saveVisuals (state, payload) {
            state.visuals = payload
        },
        saveBroll (state, payload) {
            state.broll = payload
        },
        saveOfferWallOffers (state, payload) {
            state.offerWallOffers = payload
        },
        clearTokens(state) {
            state.accessToken = null
            state.refreshToken = null
            localStorage.removeItem('adfluentialCache')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessTokenExpiresAt')
            localStorage.removeItem('refreshTokenExpiresAt')
        },
        forceTokenUpdate(state) {
            console.log("Force updating store state");
            if (state.accessToken) {
                const currentToken = state.accessToken;
                state.accessToken = null;
                state.accessToken = currentToken;
                console.log("Access token updated:", currentToken.substring(0, 10) + "...");
            } else {
                console.log("No access token to update");
            }
            
            if (state.refreshToken) {
                const currentRefreshToken = state.refreshToken;
                state.refreshToken = null;
                state.refreshToken = currentRefreshToken;
                console.log("Refresh token updated:", currentRefreshToken.substring(0, 10) + "...");
            } else {
                console.log("No refresh token to update");
            }
        }
    },  
    getters: {  
        accessToken: (state) => {
            // First try to get from state
            if (state.accessToken) {
                console.log("Access token found in state:", state.accessToken.substring(0, 10) + "...");
                return state.accessToken;
            }
            
            // Then try localStorage
            const token = localStorage.getItem('accessToken');
            const expiresAt = parseInt(localStorage.getItem('accessTokenExpiresAt'));
            console.log("Access token getter called:", 
                token ? "token exists in localStorage" : "no token in localStorage", 
                expiresAt ? `expires at ${new Date(expiresAt).toISOString()}` : "no expiry");
            
            // Only check expiration if we have both values
            if (token && expiresAt && Date.now() > expiresAt) {
                console.log("Access token is expired");
                return null;  // Token is expired but don't remove it yet
            }
            return token;
        },        
        refreshToken: (state) => {
            // First try to get from state
            if (state.refreshToken) {
                console.log("Refresh token found in state:", state.refreshToken.substring(0, 10) + "...");
                return state.refreshToken;
            }
            
            // Then try localStorage
            const token = localStorage.getItem('refreshToken');
            const expiresAt = parseInt(localStorage.getItem('refreshTokenExpiresAt'));
            console.log("Refresh token getter called:", 
                token ? "token exists in localStorage" : "no token in localStorage", 
                expiresAt ? `expires at ${new Date(expiresAt).toISOString()}` : "no expiry");
            
            // Only check expiration if we have both values
            if (token && expiresAt && Date.now() > expiresAt) {
                console.log("Refresh token is expired");
                return null;  // Token is expired but don't remove it yet
            }
            return token;
        },        
        userEmail: state => {
            return state.userEmail
        },  
        userUuid: state => {
            return state.userUuid
        },                
        platformUuid: state => {
            return state.platformUuid
        },        
        platformAccountTimezoneUuid: state => {
            return state.platformAccountTimezoneUuid
        },        
        platformAccountUuid: state => {
            return state.platformAccountUuid
        },        
        platformCampaignUuid: state => {
            return state.platformCampaignUuid
        },                
        startDate: state => {
            return state.dateRange.startDate
        },                      
        endDate: state => {
            return state.dateRange.endDate
        },                              
        startDate2: state => {
            return state.dateRange2.startDate
        },                      
        endDate2: state => {
            return state.dateRange2.endDate
        },                                      
        widgetFilter: state => {
            return state.widgetFilter
        },                                  
        subWidgetFilter: state => {
            return state.subWidgetFilter
        },                                              
        campaignFilter: state => {
            return state.campaignFilter
        },          
        tagFilter: state => {
            return state.tagFilter
        },                        
        campaignCategory: state => {
            return state.campaignCategory
        },                                
        contentFilter: state => {
            return state.contentFilter
        },             
        groupFilter: state => {
            return state.groupFilter
        },                        
        platforms: state => {
            return state.platforms
        },             
        platformAccountTimezones: state => {
            return state.platformAccountTimezones
        },             
        platformAccounts: state => {
            return state.platformAccounts
        },            
        mediaBuyers: state => {
            return state.mediaBuyers
        },
        countries: state => {
            return state.countries.filter(country => country.metaCountryEnabled)
        },
        allCountries: state => {
            return state.countries;
        },        
        languages: state => {
            return state.languages
        },        
        iabCategories: state => {
            return state.iabCategories
        },
        brands: state => {
            return state.brands
        },
        verticals: state => {
            return state.verticals.filter(vertical => vertical.metaVerticalEnabled)
        },
        partners: state => {
            return state.partners
        },               
        leadTypes: state => {
            return state.leadTypes.filter(leadType => !leadType.leadTypeDisabled)
        },                                                                                               
        users: state => {
            return state.users
        },  
        tags: state => {
            return state.tags
        },     
        actors: state => {
            return state.actors
        },     
        editors: state => {
            return state.editors
        },     
        scripts: state => {
            return state.scripts
        },     
        texts: state => {
            return state.texts
        },             
        images: state => {
            return state.images
        },             
        creativeAssets: state => {
            return state.creativeAssets
        },        
        takes: state => {
            return state.takes
        },
        hooks: state => {
            return state.hooks
        },
        locations: state => {
            return state.locations
        },
        titles: state => {
            return state.titles
        },
        descriptions: state => {
            return state.descriptions
        },  
        captions: state => {
            return state.captions
        },
        music: state => {
            return state.music
        },  
        sfx: state => {
            return state.sfx
        },
        special: state => {
            return state.special
        },
        visuals: state => {
            return state.visuals
        },
        broll: state => {
            return state.broll
        },
        offerWallOffers: state => {
            return state.offerWallOffers
        },
    }    
})

export default store
