<template>
  <div>
    <NavBar :notificationsCount="notificationsCount" v-if="this.$route.name !== 'Login' && this.$route.name !== 'TwoFactor'" />
    <router-view class="min-vh-100" />
    <b-container fluid class="pt-0">
      <b-row align-h="end">
        <b-col cols="12" lg="12" align-self="center" class="text-left mt-3 mb-4 pl-4" v-bind:class="{ 'text-center': this.$route.name === 'Login', 'text-left': !this.$route.name === 'Login' }">
          
          <b-link href="https://adfluential.com" target="_blank" class="text-dark text-decoration-none small">&copy; Adfluential Limited 2025</b-link>
          <br /><span class="text-dark small">
              <strong>UK: </strong>Adfluential Limited, Halpern House, 1 Hampshire Terrace, Portsmouth, PO1 2QF, United Kingdom - <b-link href="https://assets.adfluential.com/privacy.html" target="_blank">UK/EU Privacy Policy</b-link>
              <br><strong>US: </strong>Adfluential LLC, 447 Broadway, 2nd Floor 1674, New York, NY, New York, 10013, United States of America - <b-link href="https://assets.adfluential.com/privacy_usa.html" target="_blank">United States Privacy Policy</b-link> - <b-link href="https://assets.adfluential.com/terms_usa.html" target="_blank">United States Terms &amp; Conditions</b-link>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import store from "./store";
import axios from "axios";
import moment from "moment";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      toastCount: 0,
      userUuid: store.getters.userUuid,
      messagesShown: [],
      notificationsCount: 0,
    };
  },
  methods: {
    async displayNotifications() {
      // Skip notifications on public routes
      if (this.$route.name === 'Login' || this.$route.name === 'TwoFactor') {
        return;
      }
      
      const userUuid = store.getters.userUuid;
      if (!userUuid) {
        return;
      }

      try {
        const response = await axios.get(`/notification/${userUuid}`);
        this.notificationsCount = response.data?.length || 0;
        if (response.data != null && response.data != undefined && response.data?.length > 0) {
          let newMessages = response.data.filter((notification) => notification.notificationRead == false && !this.messagesShown.includes(notification.notificationUuid));
          newMessages = newMessages.slice(0, 5);
          newMessages.forEach((message) => {
            this.messagesShown.push(message.notificationUuid);
            let h = this.$createElement
            let title = h(
              'div',
              { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
              [
                h('strong', { class: 'mr-2' }, message.notificationTitle),
                h('small', { class: 'ml-auto text-italics' }, moment(message.notificationTimestamp).fromNow())
              ]
            );
            this.$bvToast.toast(message.notificationMessage, {
              title: [title],
              variant: message.notificationType,
              solid: true,
              noAutoHide: true,
              appendToast: true,
              id: message.notificationUuid,
              toaster: "b-toaster-bottom-right",
            });
          });
        }
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.displayNotifications();
    }, 5000);
    
    this.$root.$on("bv::toast::hidden", (event) => {
      if (!event.componentId) return;  // Add safety check
      
      axios
        .put(`/notification/update/${event.componentId}`, {
          notificationRead: true,
        })
        .then(() => {
          this.notificationsCount = Math.max(0, this.notificationsCount - 1);  // Update count
          console.log("Updated notification status");
        })
        .catch(error => {
          console.error("Failed to update notification status:", error);
        });
    });
  },
  beforeDestroy() {
  },
};
</script>

<style lang="scss">
html {
  font-size: 14px;
}
.toast {
  font-size: 1rem !important;
}

/* Override b-badge styling to make them larger and more button-like */
.badge {
  font-size: 0.875rem !important; /* Same size as normal text */
  font-weight: 500 !important;
  padding: 0.35em 0.65em !important;
  line-height: 1.2 !important;
}

/* You can also add specific styling for different badge variants if needed */
.badge-primary, .badge-secondary, .badge-success, 
.badge-danger, .badge-warning, .badge-info {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
</style>
